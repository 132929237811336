<template>
  <div :class="$style.container" :style="height">
    <div :class="$style.list" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  height?: number;
}

const props = defineProps<Props>();
const height = computed(() => ({
  '--height': `${props.height || 70}px`,
}));
</script>

<style module lang="scss">
.container {
  display: block;
}

.list {
  width: 100%;
  height: var(--height);
  background-image: url('/img/jobPosition/logo-list.png');
  background-position: left center;
  background-repeat: repeat-x;
  background-size: auto 100%;
  animation: loop 38s linear infinite;
}

@keyframes loop {
  0% {
    background-position: left center;
  }

  // ここの値は画像の縦横比に依存してます
  // width / height = 4560 / 90
  100% {
    background-position: calc(-1 * var(--height) * 4560 / 90) center;
  }
}
</style>
